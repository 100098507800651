import PageHeader from 'components/PageHeader/PageHeader';
import { FC, useEffect, default as React } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import translations from 'translations';
import media from 'utils/mediaStyle';
import utilsMessages from 'utils/messages';
import messages from './messages';
import selectContactUsPage from 'containers/ContactUsPage/selectors';
import topBg from './top-bg.png';
import { ContactUsPageState, RootState } from 'containers/ContactUsPage/types';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';

interface IStateProps {
  contactuspage: ContactUsPageState;
}

type Props = IStateProps & RouteComponentProps;

const Container = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 8pt;
  flex: 1;
  max-width: 720px;
  background-image: url(${topBg});
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 8pt;
  ${media.md`
    padding: 30pt 90pt;
  `};
  p {
    line-height: normal;
    margin: 8px 0;
  }
`;

const ReadMore = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const ReadMoreAnchor = styled.a`
  color: #2e904e;
  font-weight: bold;
  border: 1px solid #2e904e;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  &:hover {
    color: white;
    background-color: #2e904e;
  }
`;

const ContactUsSuccessPage: FC<Props> = (props) => {
  const { contactuspage, history } = props;
  const currentLang = window.localStorage.getItem('lang') || 'vi';
  useEffect(() => {
    if (!contactuspage?.isSuccess) {
      history.push('/contact-us');
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>{translations(messages.metaHeader)}</title>
        <meta name="description" content={`${translations(messages.metaHeader)}`} />
      </Helmet>
      <Container>
        <PageHeader size="large">
          <FormattedMessage {...messages.header} />
        </PageHeader>
        <FormattedMessage {...messages.sendSuccessfully} />
        <ReadMore>
          <ReadMoreAnchor
            target="_Blank"
            href={`https://kamereo.vn/${currentLang === 'vi' ? 've-kamereo' : 'about-kamereo'}`}
          >
            <FormattedMessage {...utilsMessages.readMoreAboutUs} />
          </ReadMoreAnchor>
        </ReadMore>
      </Container>
    </>
  );
};
const mapStateToProps = createStructuredSelector<RootState, IStateProps>({
  contactuspage: selectContactUsPage(),
});

const withConnect = connect(mapStateToProps);

export default compose(injectIntl, withConnect, withRouter)(ContactUsSuccessPage);
