/*
 * ContactUsSuccessPage Messages
 *
 * This contains all the text for the ContactUsPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ContactUsSuccessPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Liên hệ với chúng tôi',
  },
  metaHeader: {
    id: `${scope}.metaHeader`,
    defaultMessage: 'Liên hệ thành công',
  },
  sendSuccessfully: {
    id: `${scope}.sendSuccessfully`,
    defaultMessage:
      'Thông tin đã được gửi thành công! Hệ thống đang tạo tài khoản của bạn và liên hệ cho bạn khi hoàn thành!',
  },
});
